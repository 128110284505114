<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">角色管理</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 h-full">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <dx-data-grid :data-source="dataSource">
          <dx-column data-field="Id" data-type="string"> </dx-column>
          <dx-column data-field="UserName" data-type="string"> </dx-column>
          <dx-paging :page-size="10"></dx-paging>
          <dx-pager
            :show-page-size-selector="true"
            :allow-page-sizes="[10, 20, 50]"
          ></dx-pager>
        </dx-data-grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import createDataSource from "@/utils/devextreme";
import CloudFun, { defineComponent } from "@cloudfun/core";

import { DxDataGrid, DxColumn, DxPaging, DxPager } from "devextreme-vue/data-grid";

export default defineComponent({
  components: { DxDataGrid, DxColumn, DxPager, DxPaging },
  setup() {
    const model = CloudFun.current.model;
    const dataSource = createDataSource(model, "Id", "actionLog/load");
    return { dataSource };
  },
});
</script>